import { motion } from 'framer-motion';
import Image from 'next/image';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';

export const MotionImage = motion(Image);

export const MotionFlex = motion(Flex);

export const MotionArrowForwardIcon = motion(ArrowForwardIcon);
