
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Box, Flex, Heading, Text, Link as ChakraLink, useMediaQuery } from '@chakra-ui/react';
import Link from 'next/link';
import Image from 'next/image';
import { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { Highlight, Hits, Index, InstantSearch, SearchBox } from 'react-instantsearch-hooks-web';
import { AiOutlineArrowRight } from 'react-icons/ai';
import useTranslation from 'next-translate/useTranslation';
import { handleError } from '@/helpers/handleError';
import { GET_HEADER_DATA, GET_FOOTER_DATA } from '@/graphql/queries';
import { configFile } from '@/config';
import HeaderMenu from '@/components/HeaderMenu';
import Image404 from '@/img/404.svg';
import Image404Cloud from '@/img/404cloud.svg';
import CardService from '@/components/CardService';
import Footer from '@/components/Footer';
import { addApolloState, initializeApollo } from '@/lib/apolloClient';
import { GET_COOKIES } from '@/graphql/globalQueries';
interface Item {
    title: string;
    description?: string;
    link?: string;
}
interface Items {
    items: Item[];
}
interface PathButtonIds {
    [key: string]: string;
}
const ITEM_PATH_TO_ID: PathButtonIds = {
    '/blog': '404_blog',
    '/servicos': '404_services',
    '/contato': '404_contact'
};
function Hit({ hit }: any) {
    return (<ChakraLink _hover={{ textDecor: 'none', color: configFile.primaryAccentColor }} as={Link} href={`/${hit.url}`} bgColor="gray.50">
      <Heading as="h4" fontSize="14px" lineHeight="20px" display="flex" justifyContent="space-between" alignItems="center" color="gray.600" fontWeight="400" padding="0">
        <Highlight attribute="title" hit={hit}/> <AiOutlineArrowRight />
      </Heading>
    </ChakraLink>);
}
export default function FourOhFour({ cookies, header, navbar, footer, homepage }: any) {
    const [showResult, setShowResult] = useState<boolean>(false);
    const [isMobileScreen] = useMediaQuery('(max-width: 984px)');
    const { t } = useTranslation('common');
    const searchClient = algoliasearch(`${process.env.NEXT_PUBLIC_ALGOLIA_PROVIDER_APPLICATION_ID}`, `${process.env.NEXT_PUBLIC_ALGOLIA_PROVIDER_ADMIN_API_KEY}`);
    const items: Items['items'] = [
        {
            title: `${t('404.items.title1')}`,
            description: `${t('404.items.description1')}`,
            link: '/blog'
        },
        {
            title: `${t('404.items.title2')}`,
            description: `${t('404.items.description2')}`,
            link: '/servicos'
        },
        {
            title: `${t('404.items.title3')}`,
            description: `${t('404.items.description3')}`,
            link: '/contato'
        },
    ];
    return (<>
      <HeaderMenu cookies={cookies.data} menu={header[0].items} submenu={navbar.data?.attributes.menu_header_list}/>
      <Flex direction="column" maxWidth="1216px" margin="0 auto" width={{ base: '90%', lg: '100%' }} mb="32px" mt="100px" pt={{ base: '40px', lg: '72px' }} pb={{ base: '40px', lg: '72px' }}>
        <Flex direction="column" alignItems={{ base: 'flex-start', lg: 'center' }} justifyContent="center" py={{ base: 'none', lg: '112px' }} gap="22px">
          <Image src={Image404} alt="404" style={{ display: isMobileScreen ? 'none' : 'block' }}/>
          <Heading as="h1" textAlign="left" fontSize="72px" lineHeight="40px" fontWeight="800" color="#122345" display={{ base: 'block', lg: 'none' }}>
            404
          </Heading>
          <Heading textAlign={{ base: 'left', lg: 'center' }} as="h1" fontSize="36px" lineHeight="40px" fontWeight="800" color="#122345">
            {t('404.title')}
          </Heading>
          <Text textAlign={{ base: 'left', lg: 'center' }} fontSize="16px" lineHeight="24px" color="#122345" maxWidth="380px">
            {t('404.description')}
          </Text>
          <Image src={Image404Cloud} alt="404" style={{
            display: isMobileScreen ? 'block' : 'none',
            margin: '32px auto 0 auto'
        }}/>
        </Flex>
        <Flex direction="column" w={{ base: '100%', lg: '556px' }} m="41px auto">
          <InstantSearch searchClient={searchClient} indexName={`${process.env.NEXT_PUBLIC_ALGOLIA_BLOG_INDEX}`}>
            <Flex className="searchbox-404" alignItems="center" justifyContent="space-between" width="100%">
              <SearchBox onChangeCapture={(e) => setShowResult((e.target as HTMLInputElement).value !== '')} onResetCapture={() => setShowResult(false)} placeholder={t('404.search')} style={{
            width: '100%',
            borderRadius: '8px',
            backgroundColor: '#F7FAFC'
        }}/>
            </Flex>
            {showResult && (<>
                <Text as="h2" fontWeight="700" color="gray.600" fontSize="12px" lineHeight="16px" m="16px 0">
                  Páginas
                </Text>
                <Index indexName={`${process.env.NEXT_PUBLIC_ALGOLIA_PAGE_INDEX}`}>
                  {showResult && <Hits hitComponent={Hit}/>}
                </Index>

                <Box borderBottom="1px solid #E2E8F0"/>
              </>)}
            {showResult && (<>
                <Text as="h2" fontWeight="700" color="gray.600" fontSize="12px" lineHeight="16px" m="16px 0" bgColor="">
                  Blog
                </Text>
                <Index indexName={`${process.env.NEXT_PUBLIC_ALGOLIA_BLOG_INDEX}`}>
                  {showResult && <Hits hitComponent={Hit}/>}
                </Index>
              </>)}
          </InstantSearch>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" direction={{ base: 'column', lg: 'row' }} gap="32px">
          {items &&
            items.map((item, index) => (<Box maxWidth={{ base: '100%', lg: '384px' }} key={item.link}>
                <CardService id={ITEM_PATH_TO_ID[item.link!]} page404 index={index} title={item.title} description={item.description} link={item.link}/>
              </Box>))}
        </Flex>
      </Flex>
      <Footer menu={footer[0].items} clients={homepage.data.attributes.clients.data}/>
    </>);
}
async function getStaticProps() {
    try {
        const client = initializeApollo();
        const { data: headerData } = await client.query({
            query: GET_HEADER_DATA
        });
        const { data: footerData } = await client.query({
            query: GET_FOOTER_DATA
        });
        const { data: cookiesData } = await client.query({
            query: GET_COOKIES
        });
        return addApolloState(client, {
            props: {
                ...cookiesData,
                ...headerData,
                ...footerData
            }
        });
    }
    catch (error) {
        handleError(error, '404 getStaticProps');
    }
}

    async function __Next_Translate__getStaticProps__194852ea584__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: typeof __i18nConfig.loadLocaleFrom === 'function' ? __i18nConfig.loadLocaleFrom : (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194852ea584__ as getStaticProps }
  