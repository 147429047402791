import { Flex, Heading, Text, Link as ChakraLink } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { MotionArrowForwardIcon, MotionFlex } from '@/constants/motionConstants';
import { handleGTMClick } from '@/helpers/handleGTMClick';

interface Item {
  title: string;
  description?: string;
  link?: string;
  index: number;
  page404?: boolean;
  id: string;
}

export default function CardService({ title, description, link, index, page404, id }: Item) {
  const { t } = useTranslation('common');

  const arrowVariants = {
    hover: {
      x: 10,
      transition: {
        duration: 0.3,
      },
    },
    rest: {
      x: 0,
    },
  };

  return (
    <MotionFlex
      direction="column"
      p="32px"
      h="304px"
      flex="1"
      border={page404 ? 'none' : '1px solid #E2E8F0'}
      borderRadius="16px"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={page404 ? '#F1F6FF' : 'none'}
      _hover={{ bgColor: 'gray.100' }}
      whileHover="hover"
      initial="rest"
    >
      <ChakraLink id={id} onClick={handleGTMClick} onAuxClick={handleGTMClick} as={Link} href={link} p="0 !important">
        <Flex direction="column" gap="32px">
          <Image
            src={
              page404
                ? index === 0
                  ? '/images/card-service/icon5.svg'
                  : index === 1 /* eslint-disable */
                  ? '/images/card-service/icon2.svg'
                  : '/images/card-service/icon1.svg'
                : index === 0 /* eslint-disable */
                ? '/images/card-service/icon1.svg'
                : index === 1
                ? '/images/card-service/icon2.svg'
                : index === 2
                ? '/images/card-service/icon3.svg'
                : '/images/card-service/icon4.svg'
            }
            width="48"
            height="48"
            alt="icon"
          />
          <Flex direction="column" gap="8px">
            <Heading as="h3" color="blue.600" fontSize="24px" lineHeight="32px" fontWeight="700">
              {title}
            </Heading>
            <Text as="p" color="gray.600" fontSize="14px" lineHeight="20px">
              {description}
            </Text>
          </Flex>
        </Flex>
      </ChakraLink>
      <ChakraLink
        as={Link}
        href={link}
        color="gray.600"
        fontSize="14px"
        lineHeight="20px"
        fontWeight="500"
        p="0 !important"
      >
        {page404 ? 'Ir para' : t('homepage.ourServices.button')}
        <MotionArrowForwardIcon ml="8px" variants={arrowVariants} />
      </ChakraLink>
    </MotionFlex>
  );
}
